@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #000;
  font-family: "Inter", sans-serif;
}

.shadow-custom {
  text-shadow: 0px 4px 4px rgba(50, 202, 4, 0.25);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000;
  font-family: "Impact";

  @media (max-width: 460px) {
    text-shadow: 0px 2px 2px rgba(50, 202, 4, 0.25);
    -webkit-text-stroke-width: 1px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease-out;
}
